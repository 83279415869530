interface Translations {
    [key: string]: string | Translations
}

export const locals: Translations = {
    digsite: {
        name: "Grabung",
        nameplural: "Grabungen",
        newname: "Neue Grabung",
        fields: {
            name: "Name",
            kanton: "Kanton",
            gemeinde: "Gemeinde",
            start_date: "Startdatum",
            end_date: "Enddatum",
            project_manager: "Projektmanager"
        }
    },
    sector: {
        name: "Sektor",
        nameplural: "Sekotren",
        newname: "Neuer Sektor",
        fields: {
            digsite_id: "Grabung",
            sector_number: 'Sektornummer',
            coordinates: 'Koordinaten',
            height: 'Höhe (m.ü.M.)',
            surface: 'Fläche (m²)',
            start_date: 'Startdatum',
            end_date: 'Enddatum',
            surface_reference: 'Landschaft/Vornutzung',
            comment: 'Kommentar',
            editor: 'Bearbeiter:in',
            positions: "Positionen",
            foundsite_id: "Fundstelle"
        }
    },
    position: {
        name: "Position",
        nameplural: "Positionen",
        newname: "Neue Position",
        fields: {
            sector_id: "Sektor",
            postion_number: 'Positionsnummer',
            status: 'Zustand',
            description: 'Kurzbeschrieb',
            color: 'Farbe (nach Munsell)',
            sediment_inclusions: 'Sediment/Einschlüsse (nach Kinne)',
            stratigraphic_classification: 'Stratigraphische Einordnung',
            older_than: 'Älter als',
            younger_than: 'Jünger als',
            date: 'Datum',
            editor: 'Bearbeiter:in',
            doc_complete: 'Dokumentation abgeschlossen',
            plan_drawing_number: 'Plan/Zeichnungsnummer',
            comment: 'Kommentar',
            buildfindings_id: "Baubefund"
        }
    },
    buildfinding: {
        name: "Baubefund",
        nameplural: "Baubefunde",
        newname: "Neuer Baubefund",
        fields: { 
            older_than: "Älter als",
            younger_than: "Jünger als",
            description: "Kurzbeschrieb",
            alignment: "Ausrichtung",
            status: "Status",
            building_material: "Baumaterial",
            association: "Verbund",
            color: "Farbe (nach Munsell)",
            sediment_inclusions: "Sediment/Einschlüsse (nach Kinne)",
            positional_complex: "Positionskomplex",
            stratigraphic_classification: "Stratigraphische Einordnung",
            doc_complete: "Dokumentation abgeschlossen",
            plan_drawing_number: "Plan/Zeichnungsnummer",
            comment: "Kommentar",
            editor: "Bearbeiter:in",
            date: "Datum",
        }
    },
    foundobject: {
        name: "Fundobjekt",
        nameplural: "Fundobjekte",
        newname: "Neues Fundobjekt",
        fields: {
            description: "Kurzbeschrieb",
            comment: "Kommentar",
            material: "Material",
            editor: "Bearbeiter:in",
            date: "Datum",
            find_complex_number: "Fundkomplexnummer",
            found_object_number: "Fundobjektnummer",
            position_id: "Position",
            core_id: "Bohrkern",
            probe_id: "Sondage"
        }
    },
    sample: {
        name: "Probe",
        nameplural: "Proben",
        newname: "Neue Probe",
        fields: {
            description: "Kurzbeschrieb",
            comment: "Kommentar",
            material: "Material",
            editor: "Bearbeiter:in",
            date: "Datum",
            sample_number: "Probennummer",
            sample_type: "Probentyp",
            consumed_destroyed: "Verbraucht oder zerstört?",
            location: "Standort",
            position_id: "Position",
            core_id: "Bohrkern",
            probe_id: "Sondage",
            find_complex_number: "Fundkomplexnummer"
        }
    },
    probe: {
        name: "Sondage",
        nameplural: "Sondagen",
        newname: "Neue Sondage",
        fields: {
            description: "Kurzbeschrieb",
            comment: "Kommentar",
            material: "Material",
            editor: "Bearbeiter:in",
            date: "Datum",
            probenumber: "Sondagennummer",
            sediment_inclusions: "Sediment/Einschlüsse (nach Kinne)",
            stratigraphic_classification: "Stratigraphische Einordnung",
            sector_id: "Sektor",
            color: "Farbe (nach Munsell)"
        }
    },
    measurementdata: {
        name: "Messung",
        nameplural: "Messdaten",
        newname: "Neue Messdaten",
        fields: {
            point_name: "Punktname",
            gauge: "Gauge",
            east: "Osten",
            north: "Nord",
            hight: "Höhe (m.ü.M.)",
            timestamp: "Zeit",
            code: "Code",
            comment: "Kommentar",
            digsite_id: "Grabung",
            sector_id: "Sektor",
            position_id: "Position",
            foundobject_id: "Fundobjekt",
            core_id: "Bohrkern",
            buildfindings_id: "Baubefund",
            sample_id: "Probe",
            probe_id: "Sondage"
        }
    },
    core: {
        name: "Bohrkern",
        nameplural: "Bohrkerne",
        newname: "Neuer Bohrkein",
        fields: {
            core_number: "Punktname",
            description: "Gauge",
            color: "Farbe (nach Munsell)",
            sediment_inclusions: "Sediment/Einschlüsse (nach Kinne)",
            date: "Datum",
            code: "Code",
            comment: "Kommentar",
            sector_id: "Sektor",
        }
    },
    foundsite: {
        name: "Fundstelle",
        nameplural: "Fundstellen",
        newname: "Neue Fundstelle",
        fields: {
            digsite_id: "Grabung",
            name: "Name",
            zone: "Archäologische Zone"
        }
    }
    
    
    
}

export function getTranslationValue(translationKey: string) {
    const keyParts = translationKey.toLocaleLowerCase().split('.')

    let searchElements = locals
    let value = translationKey

    keyParts.forEach(key => {
        const searchElement = searchElements[key]

        if (!searchElement) {
            return false
        }
        else {
            if (typeof searchElement === 'string') {
                value = searchElement
                return false
            }
            else {
                searchElements = searchElement
            }
        }
    })

    return value
}