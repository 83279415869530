
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import FormContainer from "../../components/forms/FormContainer.vue";
import FormInput from "../../components/forms/FormInput.vue";
import { loginRequest, httpException } from "@/api/requests";
import { setToken } from "./../../bootstrap/state";

export default defineComponent({
  components: { FormContainer, FormInput },
  setup() {
    const store = useStore()
    const router = useRouter()
    const email = ref("");
    const password = ref("");
    const error = ref<string>();

    async function login() {
      const response = await loginRequest(email.value, password.value);

      console.log(response);

      if ("status" in response) {
        setToken(response.authorisation.token);
        store.commit("user/setEmail", response.user.email);
        store.commit("user/setFirstname", response.user.name);

        router.push("/app/home");
      } else {
        error.value = "Fehler beim Login";
      }
    }

    return {
      email,
      password,
      login,
      error,
    };
  },
});
