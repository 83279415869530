
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    layout: {
      type: String,
      default: () => "vertical",
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: () => "text",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
});
