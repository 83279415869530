
type relation = {
    way: 'up' | 'down',
    to: string
}

type property_type = 'string' | 'date' | 'relation_down' | 'number' | 'boolean' | relation

interface Model {
    [key: string]: property_type
}

interface ModelData {
    [key: string]: Model
}

export const data: ModelData = {
    Digsite: {
        name: 'string',
        kanton: 'string',
        gemeinde: 'string',
        start_date: 'date',
        end_date: 'date',
        project_manager: 'string',

        digsite_sectors: 'relation_down'
    },
    Foundsite: {
        digsite_id: {
            way: 'up',
            to: 'Digsite'
        },
        name: 'string',
        zone: 'string',
        sectors: {
            way: 'down',
            to: 'Sector'
        }
    },
    Sector: {
        foundsite_id: {
            way: 'up',
            to: 'Foundsite'
        },
        sector_number: 'string',
        coordinates: 'string',
        height: 'number',
        surface: 'number',
        start_date: 'date',
        end_date: 'date',
        surface_reference: 'string',
        comment: 'string',
        editor: 'string',
        positions: {
            way: 'down',
            to: 'Position'
        },
        probes: {
            way: 'down',
            to: 'Probe'
        },
        cores: {
            way: 'down',
            to: 'Core'
        },
        measurementDatas: {
            way: 'down',
            to: 'MeasurementData'
        }
    },
    Position: {
        postion_number: 'number',
        status: 'string',
        description: 'string',
        color: 'string',
        sediment_inclusions: 'string',
        stratigraphic_classification: 'string',
        older_than: 'string',
        younger_than: 'string',
        date: 'date',
        editor: 'string',
        doc_complete: 'boolean',
        plan_drawing_number: 'string',
        comment: 'string',
        sector_id: {
            way: 'up',
            to: 'Sector'
        },
        buildfindings_id: {
            way: 'up',
            to: 'BuildFinding'
        },
        foundobjects: {
            way: 'down',
            to: 'FoundObject'
        },
        samples: {
            way: 'down',
            to: 'Sample'
        }
    },
    BuildFinding: {
        older_than: "string",
        younger_than: "string",
        description: "string",
        alignment: "string",
        status: "string",
        building_material: "string",
        association: "string",
        color: "string",
        sediment_inclusions: "string",
        positional_complex: "string",
        stratigraphic_classification: "string",
        doc_complete: "boolean",
        plan_drawing_number: "string",
        comment: "string",
        editor: "string",
        date: "date",
    },
    FoundObject: {
        description: "string",
        comment: "string",
        material: "string",
        editor: "string",
        date: "date",
        find_complex_number: "number",
        found_object_number: "number",
        position_id: {
            way: 'up',
            to: 'Position'
        },
        core_id: {
            way: 'up',
            to: 'Core'
        },
        probe_id: {
            way: 'up',
            to: 'Probe'
        },
    },
    Sample: {
        sample_number: "number",
        description: "string",
        material: "string",
        sample_type: "string",
        consumed_destroyed: "boolean",
        find_complex_number: "string",
        location: "string",
        comment: "string",
        date: "date",
        editor: "string",
        position_id: {
            way: 'up',
            to: 'Position'
        },
        core_id: {
            way: 'up',
            to: 'Core'
        },
        probe_id: {
            way: 'up',
            to: 'Probe'
        },
    },
    Probe: {
        probenumber: "number",
        description: "string",
        color: "string",
        sediment_inclusions: "string",
        stratigraphic_classification: "string",
        comment: "string",
        date: "date",
        editor: "string",
        sector_id: {
            way: 'up',
            to: 'Sector'
        },
    },
    Core: {
        core_number: "number",
        description: "string",
        color: "string",
        sediment_inclusions: "string",
        comment: "string",
        date: "date",
        editor: "string",
        sector_id: {
            way: 'up',
            to: 'Sector'
        },
    },
    MeasurementData: {
        point_name: "string",
        gauge: "string",
        east: "string",
        north: "string",
        hight: "string",
        timestamp: "date",
        code: "string",
        comment: "string",
        digsite_id: {
            way: 'up',
            to: 'Digsite'
        },
        sector_id: {
            way: 'up',
            to: 'Sector'
        },
        position_id: {
            way: 'up',
            to: 'Position'
        },
        foundobject_id: {
            way: 'up',
            to: 'FoundObject'
        },
        core_id: {
            way: 'up',
            to: 'Core'
        },
        buildfindings_id: {
            way: 'up',
            to: 'BuildFinding'
        },
        sample_id: {
            way: 'up',
            to: 'Sample'
        },
        probe_id: {
            way: 'up',
            to: 'Probe'
        }
    },
    File: {
        file_path: "string"
    },
    Data: {
        name: "string",
        other_id: "string",
    }

}

