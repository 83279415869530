import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "value"]
const _hoisted_4 = {
  key: 1,
  class: "row mb-3"
}
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "col-sm-10" }
const _hoisted_7 = ["id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.layout === 'horizontal')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", {
          for: _ctx.name,
          class: "col-form-label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_2),
        _createElementVNode("input", {
          type: "number",
          class: "form-control",
          id: _ctx.name,
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', parseFloat($event.target.value))))
        }, null, 40, _hoisted_3)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("label", {
          for: _ctx.name,
          class: "col-sm-2 col-form-label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_5),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("input", {
            type: "number",
            class: "form-control",
            id: _ctx.name,
            value: _ctx.modelValue,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', parseFloat($event.target.value))))
          }, null, 40, _hoisted_7)
        ])
      ]))
}