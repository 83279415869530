import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import App from '@/views/App.vue'
import Home from '@/views/App/Home.vue'
import Account from '@/views/Account.vue'
import Login from '@/views/Account/Login.vue'
import Register from '@/views/Account/Register.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import Foundsites from '@/views/App/Foundsites.vue'
import Foundsite from '@/views/App/Foundsite.vue'
import Sectors from '@/views/App/Sectors.vue'
import Sector from '@/views/App/Sector.vue'
import Positions from '@/views/App/Positions.vue'
import Position from '@/views/App/Position.vue'
import Buildfindings from '@/views/App/Buildfindings.vue'
import Buildfinding from '@/views/App/Buildfinding.vue'
import FoundObjects from '@/views/App/Foundobjects.vue'
import FoundObject from '@/views/App/Foundobject.vue'
import Samples from '@/views/App/Samples.vue'
import Sample from '@/views/App/Sample.vue'
import Probes from '@/views/App/Probes.vue'
import Probe from '@/views/App/Probe.vue'
import Cores from '@/views/App/Cores.vue'
import Core from '@/views/App/Core.vue'
import MeasurementDatas from '@/views/App/Measurementdatas.vue'
import MeasurementData from '@/views/App/Measurementdata.vue'
import Digsite from '@/views/App/Digsite.vue'
import Sync from '@/views/App/Sync.vue'
import { useToken } from './state'
import { nextTick } from 'vue'

const token = useToken()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/account/login',
    meta: { title: 'Login' }
  },
  {
      path: '/app',
      name: 'App',
      component: App,
      children: [
          {
              path: '/app/home',
              name: 'App-Home',
              component: Home,
              children: [
                {
                    path: '/digsite/:id?',
                    name: 'digsite',
                    component: Digsite
                }
              ],
              meta: { title: 'Grabungen' }
          },
          {
              path: '/foundsites',
              name: 'foundsites',
              component: Foundsites,
              children: [
                {
                    path: '/foundsite/:id?',
                    name: 'foundsite',
                    component: Foundsite
                }
              ],
              meta: { title: 'Fundstelle' }
          },
          {
              path: '/sectors',
              name: 'sectors',
              component: Sectors,
              children: [
                {
                    path: '/sector/:id?',
                    name: 'sector',
                    component: Sector
                }
              ],
              meta: { title: 'Grabung' }
          },
          {
              path: '/positions',
              name: 'positions',
              component: Positions,
              children: [
                {
                    path: '/position/:id?',
                    name: 'position',
                    component: Position,
                    meta: { title: 'Position' }
                }
              ],
              meta: { title: 'Positionen' }
          },
          {
              path: '/buildfindings',
              name: 'buildfindings',
              component: Buildfindings,
              children: [
                {
                    path: '/buildfinding/:id?',
                    name: 'buildfinding',
                    component: Buildfinding,
                    meta: { title: 'Baubefund' }
                }
              ],
              meta: { title: 'Baubefunde' }
          },
          {
              path: '/foundobjects',
              name: 'foundobjects',
              component: FoundObjects,
              children: [
                {
                    path: '/foundobject/:id?',
                    name: 'foundobject',
                    component: FoundObject,
                    meta: { title: 'Fundobjekt' }
                }
              ],
              meta: { title: 'Fundobjekte' }
          },
          {
              path: '/samples',
              name: 'samples',
              component: Samples,
              children: [
                {
                    path: '/sample/:id?',
                    name: 'sample',
                    component: Sample,
                    meta: { title: 'Probe' }
                }
              ],
              meta: { title: 'Proben' }
          },
          {
              path: '/probes',
              name: 'probes',
              component: Probes,
              children: [
                {
                    path: '/probe/:id?',
                    name: 'probe',
                    component: Probe,
                    meta: { title: 'Sondage' }
                }
              ],
              meta: { title: 'Sondagen' }
          },
          {
              path: '/cores',
              name: 'cores',
              component: Cores,
              children: [
                {
                    path: '/core/:id?',
                    name: 'core',
                    component: Core,
                    meta: { title: 'Bohrkern' }
                }
              ],
              meta: { title: 'Bohrkerne' }
          },
          {
              path: '/measurementdatas',
              name: 'measurements',
              component: MeasurementDatas,
              children: [
                {
                    path: '/measurementdata/:id?',
                    name: 'measurement',
                    component: MeasurementData,
                    meta: { title: 'Messdaten' }
                }
              ],
              meta: { title: 'Messdaten' }
          },
          {
              path: '/app/sync',
              name: 'sync',
              component: Sync,
              meta: { title: 'Synchronisation' }
          }
          
      ],
      beforeEnter: (to, from, next) => {
          // TODO: Check login
          // next();
          if (token.value) {
              next()
          }
          else {
              next('/account/login')
          }
      }
  },
  {
      path: '/account',
      name: 'Account',
      component: Account,
      children: [
          {
              path: '/account/login',
              name: 'Account-Login',
              component: Login,
              meta: { title: 'Login' }
          },
          {
              path: '/account/register',
              name: 'Account-Register',
              component: Register,
              meta: { title: 'Registrieren' }
          },
      ]
  },
  {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const DEFAULT_TITLE = 'archeoData';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        // @ts-ignore
        document.title = to.meta.title ? (to.meta.title + ' - archeoData') : DEFAULT_TITLE;
    });
});

export default router
