
import { computed, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { userRequest } from "./api/requests";
import {removeToken, setToken, useToken} from "./bootstrap/state";

export default defineComponent({
  setup() {
    const token = useToken();
    const store = useStore();
    const router = useRouter();

    console.log('running version 1.15-mysql');


    const isOnline = computed(() => {
      return store.getters['sync/isOnline'] == true
    })


    async function checkUser() {

        const response = await userRequest();

        if ("status" in response) {
          store.dispatch('sync/sync')

          setToken(response.authorisation.token);
          store.commit("user/setEmail", response.user.email);
          store.commit("user/setFirstname", response.user.name);
        }
    }

    onMounted(async () => {
      if (token.value) {
        try {
          checkUser()
        }
        catch {
          // do nothing, maybe we still are validly logged in
        }

      } else {
          router.push("/account/login");
      }

      store.dispatch('sync/checkConnection')
      window.setInterval(async () => {
        store.dispatch('sync/checkConnection')

        if (isOnline.value) {
          checkUser()
        }
      }, 1000 * 60 * 5)
    });
  },
});
