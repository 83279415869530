
import store from "@/store";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    showSaveButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ['submit'],
  setup () {
    const router = useRouter()

    function back() {
      router.back()
    }

    function clickOutside(path: HTMLElement) {
      let start: HTMLElement|null = path;
      while (start !== null) {
        if (start.classList.contains('modal-content')) {
          return
        }
        start = start.parentElement
      }
      back();
    }


    const isOnline = computed(() => {
      return store.getters['sync/isOnline'] == true
    })


    return {
      back,
      clickOutside,
      isOnline
    }
  }
});
